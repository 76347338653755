<template>
  <div v-html="content" class="main ql-editor"></div>
</template>

<script>
import EtaxApi from "../../api/etax";
import "../../assets/quill.core.css";
export default {
  name: "AgreementDetail",
  props: {
    id: String,
  },
  data() {
    return {
      title: "",
      content: "",
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      EtaxApi.agreement(this.id).then((res) => {
        this.title = res.data.title;
        this.content = res.data.content;
      });
    },
  },
};
</script>

<style scoped>
.main {
  font-size: 24px;
}
</style>